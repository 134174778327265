import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LedgerDetail = _resolveComponent("LedgerDetail")!
  const _component_BasicFormDialog = _resolveComponent("BasicFormDialog")!
  const _component_BasicTablePage = _resolveComponent("BasicTablePage")!

  return (_openBlock(), _createBlock(_component_BasicTablePage, {
    searchInputs: _ctx.searchInputs,
    searchButtons: _ctx.searchButtons,
    tableColumns: _ctx.tableColumns,
    tableData: _ctx.tableData,
    pagination: _ctx.pagination,
    tableRowKey: "uGuid"
  }, {
    dialog: _withCtx(() => [
      _createVNode(_component_LedgerDetail, {
        ref: "ledgerDetailRef",
        onOnSuccess: _ctx.getTableData
      }, null, 8, ["onOnSuccess"]),
      _createVNode(_component_BasicFormDialog, {
        title: "设置分组",
        modelValue: _ctx.groupSelectDialogShow,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.groupSelectDialogShow) = $event)),
        formItems: _ctx.groupSelectFormItems,
        footerButtons: _ctx.groupSelectButtons,
        width: "500px"
      }, null, 8, ["modelValue", "formItems", "footerButtons"])
    ]),
    _: 1
  }, 8, ["searchInputs", "searchButtons", "tableColumns", "tableData", "pagination"]))
}