
import { computed, defineComponent, onActivated, reactive, ref, toRef } from 'vue'
import BasicTablePage from '@/components/BasicPageLayout/BasicTablePage.vue'
import BasicFormDialog from '@/components/BasicPageLayout/BasicFormDialog.vue'
import LedgerDetail from '@/views/ledger/LedgerAccountDetail.vue'
import usePagination from '@/utils/composables/usePagination'
import { Buttons, FormItems, Inputs, SelectOption, TableColumns } from '@/types/BasicComponent'
import { ElMessage } from 'element-plus'
import { getLedgerGroupList, getXQJZAccountList, updateXQJZLedgerGroup, XqjLedgerGroupQueryParams } from '@/api/ledger'

export default defineComponent({
  components: {
    LedgerDetail,
    BasicTablePage,
    BasicFormDialog
  },
  setup() {
    const pagination = usePagination()
    const searchData = reactive<XqjLedgerGroupQueryParams>({})
    const tableData = ref<AnyArray>([])

    onActivated(() => {
      getTableData()
    })

    const getTableData = async () => {
      const ledgerGroupResp = await getLedgerGroupList(Object.assign({}, searchData, { pageNo: 1, pageSize: 100 }))
      const ledgerGroupData = ledgerGroupResp.data.data.records as AnyArray
      ledgerGroupList.length = 0
      ledgerGroupData.forEach(data => {
        ledgerGroupList.push({
          label: data.groupName,
          value: data.gid
        })
      })
      const xqjzListResp = await getXQJZAccountList(Object.assign({ pageNo: pagination.currentPage, pageSize: pagination.pageSize }, searchData))
      tableData.value = xqjzListResp.data.data.records
      pagination.total = xqjzListResp.data.data.total
    }
    pagination.setCallback(getTableData)

    // 设置分组弹出框
    const groupSelectDialogShow = ref(false)
    const selectedGroupId = ref(1)
    const selectedXQJZId = ref(-1)
    const ledgerGroupList = reactive<SelectOption[]>([])
    const onSelectGroup = (xqjzId: number, ledgerGroupId: number) => {
      groupSelectDialogShow.value = true
      selectedXQJZId.value = xqjzId
      selectedGroupId.value = ledgerGroupId
    }
    const groupSelectFormItems = reactive<FormItems>([
      {
        label: '分账规则分组',
        type: 'select',
        prop: 'ledgerGroup',
        model: selectedGroupId,
        clearable: false,
        fullRow: true,
        options: ledgerGroupList
      }
    ])
    const groupSelectButtons = reactive<Buttons>([
      {
        label: '确认',
        onClick: async () => {
          await updateXQJZLedgerGroup(selectedXQJZId.value, selectedGroupId.value)
          ElMessage.success?.('修改分账分组成功')
          groupSelectDialogShow.value = false
          getTableData()
        }
      }, {
        label: '取消',
        type: 'default',
        onClick: () => groupSelectDialogShow.value = false
      }
    ])

    const searchInputs = reactive<Inputs>([
      {
        label: '生产机主名称',
        type: 'input',
        model: toRef(searchData, 'xqjzName'),
        clearable: true
      }, {
        label: '规则分组名称',
        type: 'input',
        model: toRef(searchData, 'groupName'),
        clearable: true
      }
    ])
    const searchButtons = reactive<Buttons>([
      {
        label: '查询',
        onClick: getTableData
      }
    ])

    const ledgerDetailRef = ref()

    const tableColumns = reactive<TableColumns>([
      {
        prop: 'index',
        type: 'index',
        label: '序号',
        width: '60px',
        align: 'center',
        index: idx => pagination.pageOffset + idx
      }, {
        prop: 'xqjzName',
        label: '生产机主',
        minWidth: '200px',
      }, {
        prop: 'ledgerGroupName',
        label: '分组名称',
        minWidth: '100px'
      }, {
        prop: 'accountBalanceSum',
        label: '账户总余额（元）',
        minWidth: '100px',
      }, {
        type: 'button',
        label: '操作',
        align: 'center',
        fixed: 'right',
        buttons: [
          {
            label: '详情',
            onClick: scope => ledgerDetailRef.value.show(scope.row.xqjzId)
          }, {
            label: '设置分组',
            onClick: scope => onSelectGroup(scope.row.xqjzId, scope.row.ledgerGroupId)
          }
        ]
      }
    ])

    return {
      pagination,
      searchInputs,
      searchData,
      searchButtons,
      tableColumns,
      tableData,
      ledgerDetailRef,
      groupSelectDialogShow,
      groupSelectFormItems,
      groupSelectButtons,
      getTableData
    }
  }
})
